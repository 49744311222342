import React from 'react';
import { sbEditable } from '@storyblok/storyblok-editable';
import sc from 'styled-components';

import DynamicComponent from './DynamicComponent';

const Page = ({ blok, location, noNavigation }) => {
  // console.log("@Page: ", blok)
  const content =
    blok.body &&
    blok.body.map((childBlok) => (
      <DynamicComponent blok={childBlok} key={childBlok._uid} location={location} noNavigation={noNavigation} />
    ));
  const hasTitle =
    blok.title && blok.title.length ? (
      <h1 className="text-5xl font-bold font-serif text-primary tracking-wide text-center py-8">{blok.title}</h1>
    ) : null;
  return (
    <div {...sbEditable(blok)}>
      <Wrapper showBackground={blok.showBackground} backgroundColor={blok.backgroundColor}>
        {hasTitle}
        {content}
      </Wrapper>
    </div>
  );
};

const Wrapper = sc.div`
  background-image: ${(p) =>
    p.showBackground ? 'url("https://a.storyblok.com/f/155939/x/97bd9e82d1/teal-bg.svg")' : 'none'};
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : 'none')};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
`;

export default Page;
